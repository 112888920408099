import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  WritableSignal,
  computed,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignalsStoreService } from '../../signals-store.service';
import { formatCurrencyForInput, formatCurrencyToAmount } from '../../utils/formatting';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OrderService } from '../../order.service';
import { ResolutionService } from '../../resolution.service';
import { model } from '@angular/core';
import { TipOptions } from '../../types/common.enums';
import { CurrencyInputDirective } from '../../directives/currency-input.directive';
import { NotificationService } from '../../notification/notification.service';

@Component({
  selector: 'app-tip',
  imports: [
    MatRadioModule,
    CommonModule,
    FormsModule,
    CurrencyInputDirective,
    MatCheckboxModule,
  ],
  templateUrl: './tip.component.html',
  styleUrl: './tip.component.scss'
})
export class TipComponent implements OnInit {

  //#region Services4

  private activeModal = inject(NgbActiveModal);
  private orderService = inject(OrderService);
  private resolutionService = inject(ResolutionService);
  private signalsStoreService = inject(SignalsStoreService);
  private notificationService = inject(NotificationService);

  //#endregion

  //#region Properties

  recurrentType = TipOptions.RECURRENT;
  oneTimeType = TipOptions.ONE_TIME;
  recurrentAmount = model('');
  oneTimeAmount = model('');
  selectedOption: WritableSignal<string> = signal('');
  showApplyToAll: WritableSignal<boolean> = signal(false);
  applyToAllOrders: WritableSignal<boolean> = signal(false);
  isMobile = computed(() => this.resolutionService.isMobile());

  //#endregion

  //#region Constructor

  ngOnInit() {
    const tip = this.orderService.odooOrder()?.paymentDetails.tip;

    if (tip?.amount) {

      this.showApplyToAll.set(true);

      this.selectedOption.set(tip?.isRecurrent ? TipOptions.RECURRENT : TipOptions.ONE_TIME);

      if (tip?.isRecurrent && (tip?.amount || this.signalsStoreService.odooTipAmount()))
        this.recurrentAmount
          .set(formatCurrencyForInput((tip?.amount || this.signalsStoreService.odooTipAmount()).toString()));
      else if (tip?.amount)
        this.oneTimeAmount
          .set(formatCurrencyForInput(tip.amount.toString()));
    }
  }

  //#endregion

  //#region Methods

  execute() {

    const isRecurrent = this.selectedOption() === this.recurrentType;

    let amount = 0;
    let value = (document.getElementById(isRecurrent ? 'recurrent-amount' : 'one-time-amount') as HTMLInputElement)?.value || '';

    if (!value) {
      return this.notificationService.show({
        text: 'Please enter a valid amount',
        type: 'error'
      });
    }

    if (value && value.endsWith('.'))
      value = value.slice(0, -1);

    const parts = value.split('.');

    if (parts.length === 1)
      value += '.00';
    else if (parts[1].length === 1)
      value += '0';

    amount = formatCurrencyToAmount(value);

    if (!amount) {
      return this.notificationService.show({
        text: 'Please enter a valid amount',
        type: 'error',
      });
    }

    this.activeModal.close({
      isRecurrent,
      amount,
      applyToAllOrders: this.applyToAllOrders()
    });
  }

  closeModal() {
    this.activeModal.close();
  }

  onOptionChange(event: string) {
    setTimeout(() => {
      document
        .getElementById(TipOptions.RECURRENT === event ? 'recurrent-amount' : 'one-time-amount')
        ?.focus();
    }, 200);
  }

  //#endregion
}
