import { Injectable, WritableSignal, computed, inject, signal } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Session } from './types/session.type';
import { LOCALSTORAGE_KEYS, FIREBASE_COLLECTIONS } from './constants/databases';
import { OrderService } from './order.service';
import { FirebaseCrudService } from './firebase-crud.service';
import { CategorySelected, Frequencies, MarketStatus, UserPreferences } from './types/common.types';
import { Subscription } from '../settings/account/subscriptions/subscriptions.types';
import { ModalContentService } from './modal-content/modal-content.service';
import { ModalContentTypes } from './constants/modal-content-types';
import { DateTime } from 'luxon';
import { environment } from '../../environments/environment';
import { eCommercePermissions } from './types/account.types';
import { FilterTypes } from './header/search-bar/search-bar.types';

@Injectable({
  providedIn: 'root'
})
export class SignalsStoreService {
  private firebaseCrudService = inject(FirebaseCrudService);
  private localStorageService = inject(LocalStorageService);
  private orderService = inject(OrderService);
  private modalContentService = inject(ModalContentService);

  anonymousUserAddress: WritableSignal<any> = signal(null);
  donationAmountSkippable: WritableSignal<number> = signal(0);
  donationVoluntary: WritableSignal<any | null> = signal(null);
  tip: WritableSignal<any | null> = signal(null);
  odooDonationAmountVoluntary: WritableSignal<number | null> = signal(null);
  odooTipAmount: WritableSignal<number | null> = signal(null);
  totalOrderAmount: WritableSignal<number> = signal(0);
  googleAddress: WritableSignal<any> = signal(null)
  hasSession: WritableSignal<any> = signal(false)
  hasSkippedWeekDelivery: WritableSignal<boolean> = signal(false);
  isContentLoaded: WritableSignal<any> = signal(false);
  isSessionLoaded: WritableSignal<any> = signal(false);
  isSidebarOpen: WritableSignal<any> = signal(true);
  sessionSignal: WritableSignal<Session | null> = signal(null);
  signUpStep: WritableSignal<any> = signal(0)
  selectedTerritory = signal<any>(null);
  selectedCategory: WritableSignal<any> = signal(null);
  firebaseOrder = signal<FirebaseOrder | any>(null);

  frequencies: WritableSignal<Frequencies[]> = signal([]);
  subscriptions: WritableSignal<Subscription[]> = signal([]);
  marketStatus: WritableSignal<MarketStatus> = signal({ isOpen: true, openingDay: '', openingHour: '' });
  filterByProducer: WritableSignal<any> = signal(null);

  showCartPreview: WritableSignal<boolean> = signal(false);

  isFiltering: WritableSignal<boolean> = signal(false);

  justAddedProduct: WritableSignal<any> = signal(null);

  shouldShowCategoriesBar: WritableSignal<boolean> = signal(true);

  isLimitedUser: WritableSignal<boolean> = signal(true);

  isAddressRequired: WritableSignal<boolean> = signal(true);

  permissions: WritableSignal<eCommercePermissions | null> = signal(null);

  isCustomBoxSignupFlow: WritableSignal<boolean> = signal(false);

  isShowingMobileFooter: WritableSignal<boolean> = signal(true);

  // This variable is set preventing for logo.svg caching in some browsers:
  // TODO: There is a better solution?
  logoVersionNumber: WritableSignal<number> = signal(0);

  // Products to remove from carousels for order and cart preview components:
  hiddenCarouselProducts: WritableSignal<number[]> = signal([]);

  categoryInViewPort = signal<CategorySelected | null>(null);
  menuSignal: WritableSignal<any[]> = signal([]);
  menu: any[] = [];

  isShopBannerHidden = signal(false);

  isInOrderComponent = signal(false);
  userPreferences = signal<UserPreferences>({
    dontShowAgainPrevDislike: {
      value: false,
      date: 0
    },
    holdUpSubscriptions: {
      value: false,
      date: 0
    }
  });

  globalMessages = signal<any[]>([]);

  numberBundleSubscribed = signal(0);


  #isInShopComponent = signal(false);
  getIsInShopComponent = computed(() => this.#isInShopComponent());
  setIsInShopComponent = (value: boolean) => this.#isInShopComponent.set(value);

  #filters: WritableSignal<Map<string, { type: FilterTypes; value: string }>> = signal(new Map());
  getFilters = computed(() => this.#filters());
  setFilters = (value: Map<string, { type: FilterTypes; value: string }>) => this.#filters.update(() => value);

  #filterChipRemoved: WritableSignal<string> = signal('');
  getFilterChipRemoved = computed(() => this.#filterChipRemoved());
  setFilterChipRemoved = (value: string) => this.#filterChipRemoved.update(() => value);

  constructor() {
    this.sessionSignal.set(this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null)
    const hasSession = !!(this.sessionSignal())?.accountInfo?.id;
    this.hasSession.set(hasSession);
    this.isLimitedUser.set(!!(this.sessionSignal()?.settings.isLimited));

    // Require address:
    if (this.sessionSignal()?.settings.requireAddress === false)
      this.isAddressRequired.set(false)
    else
      this.isAddressRequired.set(true);


    this.isSessionLoaded.set(true);
    this.setUpAnonymousAddress();

    this.logoVersionNumber.set(environment.config.filesVersionNumber.logo);
  }

  private setUpAnonymousAddress() {
    const storedAnonymousAddress: any = this.localStorageService.get(LOCALSTORAGE_KEYS.ANONYMOUS_ADDRESS);
    if (!!(storedAnonymousAddress?.street)) {
      this.anonymousUserAddress.set(storedAnonymousAddress);
    }
  }

  getAndSyncCart() {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    this.firebaseCrudService.getById(FIREBASE_COLLECTIONS.ORDERS, sessionStored.accountInfo.id.toString(), (res: FirebaseResponse) => {
      if (!res) return;

      if (this.validateExpiredFirebaseOrder(res)) {
        this.clearFBExpiredOrder();
        return;
      }

      if (res.hasOwnProperty('orderProducts')) {
        this.firebaseOrder.set(res.orderProducts)

        const tip = res?.orderProducts?.paymentDetails?.tip || null
        this.tip.set(tip)

        const donation = res?.orderProducts?.paymentDetails?.donation || null
        this.donationVoluntary.set(donation)
      }

      // This is for Klaviyo event "No Saved Cart":
      this.orderService.notSavedCartEventCreated.set(!!res.eventCreated);

      let orderProducts = null;
      // TODO changes and possible bug here related with addToOrder method
      if (res.orderProducts?.products?.common?.length || res.orderProducts?.products?.subscription?.length || res.orderProducts?.paymentDetails) {
        if (!res.orderProducts?.products?.common?.length) {
          res.orderProducts.products = {
            ...res.orderProducts.products,
            common: []
          }
        }

        if (!res.orderProducts?.products?.subscription?.length) {
          res.orderProducts.products = {
            ...res.orderProducts.products,
            subscription: []
          }
        }

        orderProducts = res.orderProducts;
      }

      this.orderService.notSavedOrder.set(orderProducts)

    })
  }

  private validateExpiredFirebaseOrder(res: FirebaseResponse): boolean {
    if (res?.expired && res?.cutoffDate) {
      if (res.orderProducts?.products?.common?.length || res.orderProducts?.products?.subscription?.length) {
        const cutoffDate = DateTime.fromMillis(res.cutoffDate).toUTC().toFormat('MM/dd/yyyy hh:mm a');
        const commonProductsLostList = this.setUpProductsLostList(res.orderProducts?.products?.common || [], 'A la carte');
        const subscriptionProductsLostList = this.setUpProductsLostList(res.orderProducts?.products?.subscription || [], 'Subscription');

        const productsLostContainer = subscriptionProductsLostList.length || commonProductsLostList.length ? `
        <ul class="list-group list-group-flush">
          ${subscriptionProductsLostList.join('')}
          ${commonProductsLostList.join('')}
        </ul>` : ''

        this.modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
          title: 'Order Expired: Unsaved Changes Lost',
          textContent: `
        <span>
          Your order has expired because the cutoff date has passed (${cutoffDate}). Unfortunately, any unsaved changes have been lost. The following items have been removed from your delivery order shopping cart:
        </span>
        <div class="col-md-12 mt-4">
          ${productsLostContainer}
        </div>
        `,
          confirmButtonText: 'I understand',
          closeable: false
        });
      }

      return true;
    }

    return false;
  }

  private setUpProductsLostList(list: any[], type: string): string[] {
    return list.map(p => {
      return `
        <li class="list-group-item d-flex justify-content-between align-items-start  text-start">
          <div class="ms-2 me-auto">
            <div class="fw-medium">${p.name}</div>
            Quantity: ${p.quantity} - Price: $${p.price}
          </div>
          <span class="badge text-bg-primary rounded-pill align-self-center">${type}</span>
        </li>
        `
    })
  }

  private clearFBExpiredOrder(): void | null {
    const sessionStored: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!sessionStored?.accountInfo?.id) return

    const updateData = { expired: false, cutoffDate: null, orderProducts: null, eventCreated: false }

    const firebasePayload = {
      collection: FIREBASE_COLLECTIONS.ORDERS,
      docId: sessionStored.accountInfo.id.toString(),
      updateData
    }

    this.firebaseCrudService.updateSubkeys(firebasePayload);
  }
}

export interface FirebaseResponse {
  cutoffDate?: number;
  expired?: boolean;
  eventCreated?: boolean;
  cartProducts?: any[];
  orderProducts?: FirebaseOrder;
}
export interface FirebaseOrder {
  products: {
    common: any[];
    subscription: any[];
  },
  paymentDetails?: {
    tip: {
      amount?: number;
      isRecurrent?: boolean;
      isDeleted?: boolean;
    };
    donation: {
      amount?: number;
      isRecurrent?: boolean;
      isDeleted?: boolean;
    };
  }
};
